var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('Wapheader'),_c('div',{staticClass:"flex-col justify-center group1"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"img3",on:{"click":function($event){return _vm.play('https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/2.9c7a3f56.mp4')}}},[_c('img',{staticStyle:{"width":"150px"},attrs:{"src":require("../../assets/images/ydzd-4.png")}})])]),_vm._m(4),_c('div',{staticClass:"group6"},[_c('div',{staticClass:"flex-col group6_1"},[_c('div',{staticStyle:{"width":"100%","text-align":"center","margin-top":"60px","font-size":"24px","font-weight":"bold"}},[_vm._v("电池服务资费方案")]),_c('div',{staticStyle:{"width":"100%","text-align":"center","font-size":"16px","margin-top":"10px"}},[_vm._v("选择BaaS，用户将不必购买电池")]),_c('div',{staticStyle:{"width":"100%","text-align":"center","font-size":"16px","margin-top":"5px"}},[_vm._v(" 按需租用特定电池包")]),_c('div',{staticStyle:{"font-size":"12px","margin-top":"40px"}},[_vm._v("更新日期： 2022 / 11 / 20")]),_vm._m(5),(_vm.show)?_c('div',{staticClass:"flex-col",staticStyle:{"margin":"50px auto"},on:{"click":_vm.show1}},[_c('span',{staticStyle:{"font-size":"14px","font-weight":"bold"}},[_vm._v("查看更多租电方案")]),_c('span',{staticStyle:{"height":"2px","background-color":"black","padding-top":"2px","width":"113px"}})]):_vm._e(),(_vm.isshow)?_c('div',[_vm._m(6),_vm._m(7)]):_vm._e()])]),_c('div',{staticClass:"flex-col group7"},[_c('div',{staticClass:"flex-col justify-center"},[_vm._m(8),_vm._m(9),_c('div',{staticClass:"flex-col",staticStyle:{"width":"100%","text-align":"center","margin-top":"30px"}},[_c('span',{on:{"click":function($event){return _vm.navPath('/newwapcnw')}}},[_vm._v("了解车能网")]),_c('span',{staticStyle:{"width":"68px","height":"2px","background-color":"black","padding-top":"2px","margin":"0 auto"}})])])]),_c('Wapfooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img1"},[_c('img',{staticStyle:{"width":"200px","height":"80px"},attrs:{"data-aos":"fade-up","src":require("../../assets/images/ydzd-3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img2"},[_c('img',{staticStyle:{"width":"320px"},attrs:{"data-aos":"fade-up","src":require("../../assets/images/ydzd-2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col group2"},[_c('span',{staticStyle:{"color":"white","font-size":"28px"}},[_vm._v("光阳电动BaaS")]),_c('span',{staticStyle:{"color":"white","font-size":"28px"}},[_vm._v("电池租用服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col group3"},[_c('span',{staticStyle:{"color":"white","font-size":"14px"}},[_vm._v("轻便骑行，城市代步 ")]),_c('span',{staticStyle:{"color":"white","font-size":"14px"}},[_vm._v("适合日常通勤骑行需求")]),_c('span',{staticStyle:{"color":"white","font-size":"14px"}},[_vm._v("每一次通勤能量加满 ")]),_c('span',{staticStyle:{"color":"white","font-size":"14px"}},[_vm._v("上班路上更省心！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col group4"},[_c('div',{staticClass:"flex-col group5"},[_c('span',{staticStyle:{"color":"white","font-size":"24px"}},[_vm._v("开启电动出行的新纪元")]),_c('div',{staticClass:"flex-row justify-center",staticStyle:{"margin-top":"20px"}},[_c('span',{staticStyle:{"color":"white","font-size":"14px"}},[_vm._v("电摩拥车新方式")]),_c('span',{staticStyle:{"color":"#00ccff","font-size":"14px","margin-left":"20px"}},[_vm._v("可租、可换、可升级")])])]),_c('div',{staticClass:"swiper mySwiper",staticStyle:{"width":"100%","margin-top":"40px","height":"560px"}},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('img',{staticClass:"img4",attrs:{"src":require("../../assets/images/cdyw/sj/swiper-banner11.png"),"alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{staticClass:"img4",attrs:{"src":require("../../assets/images/cdyw/sj/swiper-banner12.png"),"alt":""}})]),_c('div',{staticClass:"swiper-slide"},[_c('img',{staticClass:"img4",attrs:{"src":require("../../assets/images/cdyw/sj/swiper-banner13.png"),"alt":""}})])]),_c('div',{staticClass:"swiper-pagination"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col section1",staticStyle:{"margin-top":"30px"}},[_c('div',{staticStyle:{"font-size":"24px"}},[_vm._v("惠生活系列")]),_c('div',{staticStyle:{"font-size":"14px","margin-top":"10px"}},[_c('strong',[_vm._v("轻便骑行，城市代步。")]),_vm._v("适合日常通勤骑行需求，"),_c('br'),_vm._v("每一次通勤能量加满，上班路上更省心!")]),_c('img',{staticStyle:{"margin-top":"15px"},attrs:{"src":require("../../assets/images/ydzd-j1.png")}}),_c('div',{staticStyle:{"font-size":"8px","margin-top":"10px"}},[_vm._v("仅限i-OneX、i-OneXS用户，搭配48V28Ah力神21700锂电池 超出后如需换电，"),_c('strong',[_vm._v("按4元/次收费")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col section1",staticStyle:{"margin-top":"30px"}},[_c('div',{staticStyle:{"font-size":"24px"}},[_vm._v("自在骑系列")]),_c('div',{staticStyle:{"font-size":"14px","margin-top":"10px"}},[_c('strong',[_vm._v("乐享出行，畅快骑行。")]),_vm._v("开启轻松自在的骑行生活，"),_c('br'),_vm._v("进阶车规级锂电池，享更强动力安全出行!")]),_c('img',{staticStyle:{"margin-top":"15px"},attrs:{"src":require("../../assets/images/ydzd-j2.png")}}),_c('div',{staticStyle:{"font-size":"8px","margin-top":"10px"}},[_vm._v("仅限i-OneX、i-OneXS用户，搭配48V28Ah力神21700锂电池 超出后如需换电，"),_c('strong',[_vm._v("按4元/次收费")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col section1",staticStyle:{"margin-top":"30px","margin-bottom":"100px"}},[_c('div',{staticStyle:{"font-size":"24px"}},[_vm._v("探路者系列")]),_c('div',{staticStyle:{"font-size":"14px","margin-top":"10px"}},[_c('strong',[_vm._v("一骑绝尘，性能专享。")]),_vm._v("高性能电池，高电压平台，"),_c('br'),_vm._v("让你骑得更快、更远、更安全！")]),_c('img',{staticStyle:{"margin-top":"15px"},attrs:{"src":require("../../assets/images/ydzd-j1.png")}}),_c('div',{staticStyle:{"font-size":"8px","margin-top":"10px"}},[_vm._v("仅限i-OneX、i-OneXS用户，搭配48V28Ah力神21700锂电池 超出后如需换电，"),_c('strong',[_vm._v("按4元/次收费")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","text-align":"center","margin-top":"60px"}},[_c('img',{staticStyle:{"width":"80%"},attrs:{"src":require("../../assets/images/ydzd-6.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-col",staticStyle:{"width":"100%","text-align":"center","margin-top":"30px"}},[_c('span',[_vm._v("『Ionex车能网』智能安全出行生态系统")]),_c('span',[_vm._v(" 全面开创两轮智能出行新体验，")]),_c('span',[_vm._v("是全球电动车市场最佳智能化解决方案")])])
}]

export { render, staticRenderFns }