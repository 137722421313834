<template>
    <div class="page">
        <Wapheader />
        <div class="flex-col justify-center group1">
          <div  class="img1">
            <img  data-aos="fade-up" src="../../assets/images/ydzd-3.png" style="width: 200px;height: 80px;"/>
          </div>
          <div class="img2">
            <img data-aos="fade-up" src="../../assets/images/ydzd-2.png" style="width: 320px;"/>
          </div>
          <div class="flex-col group2" >
             <span style="color: white; font-size:28px;">光阳电动BaaS</span>
             <span style="color: white; font-size:28px;">电池租用服务</span>
          </div>
          <div class="flex-col group3" >
             <span style="color: white; font-size:14px;">轻便骑行，城市代步  </span>
             <span style="color: white; font-size:14px;">适合日常通勤骑行需求</span>
             <span style="color: white; font-size:14px;">每一次通勤能量加满 </span>
             <span style="color: white; font-size:14px;">上班路上更省心！</span>
          </div>
          <div class="img3"  @click="play('https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2022/images/2.9c7a3f56.mp4')" >
            <img src="../../assets/images/ydzd-4.png" style="width: 150px;"/>
          </div>
        </div>
        <div class="flex-col group4">
            <div class="flex-col group5">
                <span style="color: white; font-size: 24px;">开启电动出行的新纪元</span>
                <div class="flex-row justify-center" style="margin-top: 20px;">
                    <span style="color: white;font-size: 14px;">电摩拥车新方式</span>
                    <span style="color: #00ccff;font-size: 14px;margin-left: 20px;">可租、可换、可升级</span>
                </div>
            </div>
            <div class="swiper mySwiper" style="width: 100%;margin-top: 40px; height: 560px;">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img class="img4" src="../../assets/images/cdyw/sj/swiper-banner11.png" alt="">
                </div>
                <div class="swiper-slide">
                    <img class="img4" src="../../assets/images/cdyw/sj/swiper-banner12.png" alt="">
                </div>
                <div class="swiper-slide">
                    <img class="img4" src="../../assets/images/cdyw/sj/swiper-banner13.png" alt="">
                </div>
            </div>
            <div class="swiper-pagination" ></div>
        </div>
 
        </div>
        <div class="group6">
            <div class="flex-col group6_1">
                <div style="width: 100%;text-align: center;margin-top: 60px;font-size: 24px;font-weight: bold;">电池服务资费方案</div>
                <div style="width: 100%;text-align: center;font-size: 16px;margin-top: 10px;">选择BaaS，用户将不必购买电池</div>
                <div style="width: 100%;text-align: center;font-size: 16px;margin-top: 5px;"> 按需租用特定电池包</div>
                <div style="font-size: 12px;margin-top: 40px;">更新日期： 2022 / 11 / 20</div>
                <div class="flex-col section1" style="margin-top: 30px;">
                     <div style="font-size: 24px;">惠生活系列</div>
                     <div style="font-size: 14px;margin-top: 10px;"> <strong>轻便骑行，城市代步。</strong>适合日常通勤骑行需求，<br/>每一次通勤能量加满，上班路上更省心!</div>
                     <img src="../../assets/images/ydzd-j1.png" style="margin-top: 15px;"/>
                     <div style="font-size: 8px;margin-top: 10px;">仅限i-OneX、i-OneXS用户，搭配48V28Ah力神21700锂电池 超出后如需换电，<strong>按4元/次收费</strong></div>
                </div>
                <div class="flex-col" style="margin: 50px auto;" v-if="show" @click="show1">
                    <span style="font-size: 14px;font-weight: bold;">查看更多租电方案</span>
                    <span style="height: 2px;background-color: black;padding-top: 2px;width: 113px;"></span>
                </div>
                <div v-if="isshow">
                    <div class="flex-col section1" style="margin-top: 30px;">
                     <div style="font-size: 24px;">自在骑系列</div>
                     <div style="font-size: 14px;margin-top: 10px;"> <strong>乐享出行，畅快骑行。</strong>开启轻松自在的骑行生活，<br/>进阶车规级锂电池，享更强动力安全出行!</div>
                     <img src="../../assets/images/ydzd-j2.png" style="margin-top: 15px;"/>
                     <div style="font-size: 8px;margin-top: 10px;">仅限i-OneX、i-OneXS用户，搭配48V28Ah力神21700锂电池 超出后如需换电，<strong>按4元/次收费</strong></div>
                    </div>
                    <div class="flex-col section1" style="margin-top: 30px;margin-bottom: 100px;">
                     <div style="font-size: 24px;">探路者系列</div>
                     <div style="font-size: 14px;margin-top: 10px;"> <strong>一骑绝尘，性能专享。</strong>高性能电池，高电压平台，<br/>让你骑得更快、更远、更安全！</div>
                     <img src="../../assets/images/ydzd-j1.png" style="margin-top: 15px;"/>
                     <div style="font-size: 8px;margin-top: 10px;">仅限i-OneX、i-OneXS用户，搭配48V28Ah力神21700锂电池 超出后如需换电，<strong>按4元/次收费</strong></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-col group7">
            <div class="flex-col justify-center">
                <div style="width: 100%;text-align: center;margin-top: 60px;">
                    <img src="../../assets/images/ydzd-6.png" style="width: 80%;"/>
                </div>
                <div class="flex-col" style="width: 100%;text-align: center;margin-top: 30px;">
                    <span>『Ionex车能网』智能安全出行生态系统</span>
                    <span> 全面开创两轮智能出行新体验，</span>
                    <span>是全球电动车市场最佳智能化解决方案</span>
                </div>
                <div class="flex-col" style="width: 100%;text-align: center;margin-top: 30px;">
                    <span @click="navPath('/newwapcnw')" >了解车能网</span>
                    <span style=" width:68px;height: 2px;background-color: black;padding-top: 2px;margin: 0 auto;"></span>
            </div>
            </div>
          
        </div>
        <Wapfooter />
    </div>
</template>
<script>
import { Swipe, SwipeItem } from 'vant';
export default {
    components: {
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
    },
    data() {
        return {
            isshow:false,
            show:true,
        }
    },
    mounted() {
        this.$nextTick(() => {
            
            new Swiper('.mySwiperNew', {
                loop: false,
                slidesPerView:1.5,
                autoplay: true,//可选选项，自动滑动
                centeredSlides: true,
                spaceBetween: 40,
            })
            new Swiper('.mySwiper', {
                loop: true,
                autoplay: true,//可选选项，自动滑动
                pagination: {
                    el: ".swiper-pagination",
                },
            })
            new Swiper('.mySwiper1', {
                loop: true,
                autoplay: true,//可选选项，自动滑动
                pagination: {
                    el: ".swiper-pagination",
                },
            })
        })
    },
    methods:{
        show1(){
            this.isshow=true,
            this.show=false
        },
    }
}
</script>
<style>
.van-swipe__track {
    display: flex;
}
.mySwiper .swiper-pagination-bullet{
    width: 120px ;
    border-radius: 0;
    height: 5px ;
    background-color: darkgrey ;
    
}
</style>
<style lang="less" scoped>

.group1{
    background-image: url('../../assets/images/ydzd-1.png');
    background-size:100% 100%;
    height: 1334px;
    width: 100%;
    position: relative;
}
.img1{
    width: 100%;
    position: absolute;
    top:130px;
    text-align: center;
}
.img2{
    width: 100%;
    position: absolute;
    top:300px;
    text-align: center;
}
.group2{
    width: 100%;
    position: absolute;
    top:600px;
    text-align: center;
}
.group3{
    width: 100%;
    position: absolute;
    top:800px;
    text-align: center;
}
.img3{
    width: 100%;
    position: absolute;
    top:1040px;
    text-align: center;
}
.group4{
    height: 100vh;
    background-color: #010023;
}
.group5{
    width:100%;
    text-align: center;
    margin-top: 200px;
}
.img4{
    padding: 0 40px;
}

.group6{
    background-color: #EBEBEB;
}
.group6_1{
    padding: 0 60px;
}
.group7{
    height: 1334px;
   background-image: url('../../assets/images/ydzd-5.png');
   background-size: 100% 100%;
}
</style>